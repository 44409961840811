<template>
<div style="width: 100%;height: 100%">
  <img ref="tifImage" v-if="!show" :src="img" alt=''>
  <img v-if="show" class="img-rotate" :src="img" alt='Animation'>
</div>
</template>

<script>
import { toPng } from 'html2canvas';
import { convertBlobToDataURL } from 'canvas-to-blob';
export default {
name: "projectImg",
  data() {
    return {
      img:'',
      show: false
    }
  },
   created() {
    // console.log(this.$route.query.url)
    // console.log(this.$route.params)
    console.log(this.$route.query)
    if (this.$route.query.name.split('.').pop() === 'gif') {
      this.img = this.$route.query.url
      this.show = true
    } else if (this.$route.query.name.split('.').pop() === 'tif')  {
      this.img = this.$route.query.url
      // this.convertTifToPng()
        // const imageElement = this.$route.query.url;
        // const canvas = await toPng(imageElement);
        // const blob = await canvas.convertToBlob();
        // const pngImageDataURL = await convertBlobToDataURL(blob);
        // this.img = pngImageDataURL;
      // console.log(imageConversion)
      // imageConversion.compress(this.$route.query.url, {
      //   format: 'png',
      //   quality: 0.8 // 图片质量，可选参数
      // }).then((result) => {
      //   console.log(result)
      //   // result为转换后的图片数据
      //   this.img = result
      // }).catch((error) => {
      //   console.error(error);
      // });
    } else {
      console.log(99)
      this.img = this.$route.query.url
      this.show = false
    }
  },
  mounted() {
    console.log(this.$refs.tifImage)
  },
  methods: {
    async convertTifToPng() {
      if (this.$refs.tifImage) {
        const imageElement = this.$refs.tifImage;
        console.log(imageElement)
        const canvas = await toPng(imageElement);
        const blob = await canvas.convertToBlob();
        const pngImageDataURL = await convertBlobToDataURL(blob);
        console.log(canvas)
        this.img = pngImageDataURL;
      }
    },
  }
}
</script>

<style scoped lang="scss">
img{
  margin: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img-rotate {
  animation: rotate 6s linear infinite;
  top: 40%;
  //top: 28% !important; /* 上边界与容器顶部之间的距离为50% */
  //left: 46% !important; /* 左边界与容器左侧之间的距离为50% */
}

@keyframes rotate {
  0% {
    transform: rotate(-720deg);
  }
  /* 初始状态 */
  100% {
    transform: rotate(0deg);
  }
  /* 旋转到指定角度 */
  //100% { transform: rotate(0deg); } /* 结束状态 */
}
</style>
